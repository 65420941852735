<template>
	<HeadingX class="c-base-h3" v-bind="{ tag }" level-offset="2">
		<template v-if="domProps.textContent">
			{{ domProps.textContent }}
		</template>
		<template v-else>
			<slot></slot>
		</template>
	</HeadingX>
</template>

<script>
import HeadingX from '~/citi-baseline/components/HeadingX';

export default {
	name: 'BaseH3',
	components: { HeadingX },

	props: {
		tag: {
			type: String,
			default: undefined,
		},
	},

	computed: {
		domProps() {
			const { domProps } = this.$vnode?.data || {};
			return domProps || {};
		},
	},
};
</script>

<style lang="postcss">
:where(.c-base-h3) {
	@apply text-h3 font-darker-grotesque font-medium;
}
</style>
